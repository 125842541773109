module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-theme-weather","icon":"/opt/build/repo/theme/images/favicon.png","short_name":"weather","start_url":"/","background_color":"#FDF8F7","theme_color":"#c6797e","display":"standalone"},
    },{
      plugin: require('../../theme/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-theme-weather","short_name":"weather","start_url":"/","background_color":"#FDF8F7","theme_color":"#c6797e","display":"standalone"},
    }]
